import { useEffect, useRef, useState } from "react";
import { editor } from 'monaco-editor';
import { BsPlayFill } from 'react-icons/bs';

function App() {
  const [output, setOutput] = useState('');
  const [pyodide, setPyodide] = useState(null);

  useEffect(() => {
    async function load() {
      const pyodide = await window.loadPyodide({
        indexURL: "https://cdn.jsdelivr.net/pyodide/v0.18.1/full/",
        stdout: n => setOutput(o => o + n + "\n"),
      });
      setPyodide(pyodide);
    }
    load();

    return () => { };
  }, [])


  return (
    <>

      <section id="editor">
        <div className="row no-gutters">
          <div className="col-md-8 col-sm-12">
            <div className="python-editor">
              {pyodide ? <Editor pyodide={pyodide} /> : <h2>Loading...</h2>}
            </div>
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="python-terminal">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <button className="btn btn-sm btn-outline-secondary">Close</button>
                  <h4 style={{ color: 'white', marginBottom: '0' }}>Output</h4>
                </div>
                <div className="d-flex align-items-center">
                  <button className="btn btn-sm btn-outline-secondary">Copy</button>
                  <button className="btn btn-sm btn-outline-secondary" onClick={() => setOutput('')} style={{ float: 'right' }}>Clear</button>
                </div>
              </div>
              <p className="pt-5 pl-4"><pre style={{ fontFamily: 'monospaced', color: 'white' }}>{output}</pre></p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" id="panel-how">
        <div className="container">
          <div className="row pt-md-5 py-2 pb-1">
            <div className="col-md-4 mb-5 ml-auto">

              <div>
                <img src="/img/code.svg" />
              </div>
              <div className="pt-3">
                <h2>Write your code</h2>
                <p>Just type any Python code in the code editor. No browser emulation — just a real server to run your code on.</p>
              </div>

              <div className="pt-5">
                <img src="/img/lab.svg" />
              </div>
              <div className="pt-3">
                <h2>Run and test</h2>
                <p>When you've ritten some code you want to know if it actually works, right? Run it with a button click and see the live results.</p>
              </div>

              <div className="pt-5">
                <img src="/img/link.svg" />
              </div>
              <div className="pt-3">
                <h2>Share it!</h2>
                <p>You've just created magic. Now share your Python snippet with anyone, the possibilities are literally endless.</p>
              </div>

            </div>

          </div>
        </div>
      </section>

      <section className="py-5" id="panel-upgrade">
        <div className="container">
          <div className="row pt-md-5 py-2 pb-1 align-items-center">

            <div className="p-4 p-md-5 col-md-6 mb-5 ml-auto dark-background text-center rounded-lg">

              <div className="pb-4">
                <img style={{ width: '140px' }} src="/img/PyPodsPro_logo_white.svg" />
              </div>
              <h2 className="pb-3" style={{ color: "white", maxWidth: 400, margin: "0 auto" }} >Become a pro and enjoy extra features</h2>


              <form className="pro-form pt-3 pt-md-4">
                <div className="col-6 ml-auto"><h6 style={{ color: '#60C593' }}>Save 20% with an annual plan</h6></div>
                <fieldset className="radio-switch mb-4">
                  <input type="radio" className="custom-radio-control show-for-sr" name="project" value="SJDEV" id="project_sjdev" checked /><label for="project_sjdev" ><b>$5/month</b><br></br>Paid monthly</label>
                  <input type="radio" className="custom-radio-control show-for-sr" name="project" value="MT" id="project_mt" /><label for="project_mt"><b>$4/month</b><br></br>Paid annually</label>
                </fieldset>

                <input className="mb-4" type="text" placeholder="Card number" />
              </form>

              <button className="btn btn-primary btn-lg mb-2 mx-auto btn-block" type="button">Become a Pro for $48/year</button>
              <img style={{ width: "9px", marginTop: '-1px', marginRight: '2px' }} src="/img/lock-solid.svg" /> <h6 style={{ display: 'inline' }}>Secure payment</h6>

            </div>

            <div className="col-md-4 mb-5 ml-auto">

              <h5>PyPods Pro features</h5>

              <div className="d-flex pt-4">
                <div>
                  <img src="/img/code.svg" />
                </div>
                <div className="pl-3">
                  <h3>Private Pods</h3>
                  <p>Change your Pods from being publicly accessible to a password-protect link.</p>
                </div>
              </div>

              <div className="d-flex pt-4">
                <div>
                  <img src="/img/lab.svg" />
                </div>
                <div className="pl-3">
                  <h3>Personal Library</h3>
                  <p>Save Pods to your personal library, neatly organized and easy to find.</p>
                </div>
              </div>

              <div className="d-flex pt-4">
                <div>
                  <img src="/img/link.svg" />
                </div>
                <div className="pl-3">
                  <h3>Remove ads</h3>
                  <p>All ads in the editor and listing pages are turned completely off.</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>

      <section className="py-5" id="sign-in">
        <div className="container">
          <div className="p-3 p-md-5 col-lg-5 mb-5 mx-auto text-center">

            <div className="pb-4">
              <img style={{ width: '100px' }} src="/img/PyPods_logo_dark.svg" />
            </div>
            <h2 className="pt-5">Sign in</h2>


            <form className="pt-4">
              <input className="mb-4" type="text" placeholder="Username or email" />
              <input className="mb-2" type="text" placeholder="Password" />
              <div className="d-flex justify-content-between mb-3">

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label class="form-check-label" for="flexCheckDefault">
                    <h6 style={{ color: '#B7C3D2' }}>Default checkbox</h6>
                  </label>
                </div>

                <h6 style={{ display: 'inline', color: '#B7C3D2' }}><a href="#">Forgot password?</a></h6>
              </div>
            </form>

            <button className="btn btn-primary btn-lg mb-2 mx-auto btn-block" type="button">Sign in</button>
            <h6 style={{ display: 'inline', color: '#B7C3D2' }}>No account yet? <a href="#">Register now</a></h6>

          </div>
        </div>
      </section>



      <div className="col-lg-12 pb-4 text-center">
        <small style={{ color: '#B7C3D2' }}>Copyright &copy; {(new Date).getFullYear()} &nbsp;PyPods, a <a href="https://bitgate.com/" target="_blank">Bitgate</a> platform. All Rights Reserved.</small>
      </div>

    </>
  );
}

const Editor = ({ addOutput, pyodide }) => {
  const [currentEditor, setE] = useState(null);

  useEffect(() => {
    const e = editor.create(document.getElementById('code-container'), {
      value: "# Welcome to PyPods!\n# Start writing some code, we're all set. ;-)\n\ndef say_hi(name):\n  print('Hi there, %s!' % (name,))\n\nsay_hi('legend');\n",
      language: 'python'
    });
    setE(e);

    return () => { e.dispose() }
  }, []);

  return (<>
    <div style={{ marginBottom: 8 }}><button onClick={() => {
      if (currentEditor) {
        console.log(pyodide.runPython(currentEditor.getValue()));
      }
    }} className="btn btn-success" style={{ float: 'right' }}>Run <BsPlayFill /></button></div>

    <div id="code-container" style={{ height: '100%' }}></div>
  </>);
}

export default App;
